<template>
  <v-container fluid>
    <form ref="form" @submit.prevent="submitForm">
      <div style="padding-bottom: 1rem;display: flex;" >
        <v-btn color="warning" @click="showInstructionsDialog = true">
          Instructies stap {{ e13 }}</v-btn
        >
        <v-btn style="margin-left:5px" @click="resetFields()"> Velden leegmaken</v-btn>
      </div>
    
      <v-stepper v-model="e13" vertical>
        <v-stepper-step step="1"> Kavel informatie </v-stepper-step>

        <v-stepper-content step="1">
          <v-card>
            <v-card-title> Kavel </v-card-title>
            <v-row> </v-row>
            <v-row>
              <v-col cols="4">
                <label>Kavel land*</label>
                <v-autocomplete
                  dense
                  outlined
                  :items="landen.flat()"
                  label="NL Land selecteren"
                  item-text="Ned"
                  item-value="id"
                  v-model="form.land"
                ></v-autocomplete>
              </v-col>

              <v-col cols="4">
                <label> Kavel prijs*</label>
                <v-text-field
                  dense
                  outlined
                  placeholder="Prijs"
                  prefix="€"
                  type="number"
                  v-model="form.price"
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <label> Kavel prijs*</label>
                <v-text-field
                  dense
                  outlined
                  type="number"
                  placeholder="Inkoopprijs"
                  prefix="€"
                  v-model="form.purchasePrice"
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <label>Embcode kavel selecteren*</label>
                <v-autocomplete
                  dense
                  outlined
                  :items="embcodes.flat()"
                  label="Embcode selecteren"
                  v-model="form.embNed"
                  item-text="nameNL"
                  item-value="nameNL"
                ></v-autocomplete>
              </v-col>
              <v-col cols="4">
                <label>Embcode Amerikaanse website(optioneel)</label>
                <v-autocomplete
                  dense
                  outlined
                  :items="embcodes.flat()"
                  label="Embcode selecteren"
                  v-model="form.embEN"
                  item-text="nameEN"
                  item-value="nameEN"
                ></v-autocomplete>
              </v-col>
              <v-col cols="4">
                <label> Kavel Status* </label>
                <v-autocomplete
                  dense
                  outlined
                  :items="statussen.flat()"
                  label="Status selecteren"
                  v-model="form.status"
                  item-text="name"
                  item-value="id"
                ></v-autocomplete>
              </v-col>
              <v-col cols="4">
                <label> Kavel gewicht* </label>
                <v-text-field
                  dense
                  outlined
                  type="number"
                  placeholder="Gewicht kavel"
                  v-model="form.weight"
                >
                </v-text-field>
              </v-col>

              <v-col cols="4">
                <label> Kavel inhoud </label>

                <v-autocomplete
                  dense
                  outlined
                  :items="states.flat()"
                  label="Inhoud selecteren"
                  v-model="form.inhoudNL"
                  item-text="nameNL"
                  item-value="name"
                ></v-autocomplete>
              </v-col>

              <v-col cols="4">
                <label> Is de kavel gekoppeld aan een handelaar</label>

                <v-autocomplete
                  dense
                  outlined
                  :items="traders.flat()"
                  label="Handelaar selecteren"
                  item-text="name"
                  item-value="id"
                  v-model="form.trader"
                ></v-autocomplete>
              </v-col>
              <v-col cols="4">
                <label>Kenmerk (niet verplicht)</label>

                <v-text-field
                  dense
                  outlined
                  label="kenmerk "
                  v-model="form.kenmerk"
                ></v-text-field>
              </v-col>

              <v-col cols="4">
                <label> Op verzoek</label>

                <v-autocomplete
                  dense
                  outlined
                  :items="request.flat()"
                  label="Op verzoek selecteren"
                  item-text="name"
                  item-value="name"
                  v-model="form.onRequest"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <div style="margin-top: 5px">
              <v-btn color="primary" @click="e13 = 2"> Volgende </v-btn>

              <v-btn color="error" @click="backToKavels()" text>
                Annuleer
              </v-btn>
            </div>
          </v-card>
        </v-stepper-content>

        <v-stepper-step step="2"> Omschrijving </v-stepper-step>

        <v-stepper-content step="2">
          <v-card>
            <v-row>
              <v-col cols="6">
                <label> Omschrijving *</label>
                <textarea
                  dense
                  class="form-control"
                  rows="4"
                  placeholder="Omschrijving"
                  data-counter="350"
                  name="description"
                  cols="50"
                  id="description"
                  v-model="form.txtNed"
                ></textarea>
              </v-col>

              <v-col cols="6">
                <label> Engelse Omschrijving *</label>
                <textarea
                  dense
                  class="form-control"
                  rows="4"
                  placeholder="Description"
                  data-counter="350"
                  name="description"
                  cols="50"
                  id="description"
                  v-model="form.txtEN"
                ></textarea>
              </v-col>
            </v-row>
            <div style="margin-top: 5px">
              <v-btn color="primary" @click="addKavel()">
                Kavel toevoegen
              </v-btn>
              <v-btn style="margin: 2px" color="error" @click="backToKavels()">
                Annuleer
              </v-btn>
              <v-btn color="warning" @click="e13 = 1" text> Vorige </v-btn>
            </div>
          </v-card>
        </v-stepper-content>
      </v-stepper>
    </form>
    <v-dialog v-model="showInstructionsDialog" max-width="500px">
      <v-card>
        <v-card-title>Instructies</v-card-title>
        <v-card-text>
          <template v-if="e13 === 1">
            <p>
              <b>Kavel land</b> Selecteer een land waar de kavel vandaan komt
            </p>

            <p>
              <b>Kavel inkoopprijs</b> De inkoopprijs wordt automatisch berekend
              en weergegeven in het veld "Kavel inkoopprijs".
            </p>

            <p>
              <b>Kavel prijs</b>Vul de prijs van de kavel in het veld "Kavel
              prijs" in.
            </p>

            <p><b>Embcode kavel</b> Selecteer een embcode</p>

            <p>
              <b>Kavel Status </b>Met de status wordt bepaald of een kavel
              online is..
            </p>

            <p>
              <b>Kavel zichtbaarheid</b>Hier wordt bepaald op welke website de
              kavel online is.
            </p>

            <p><b>Aantal</b>Vul het aantal van de kavels in.</p>

            <p>
              <b>Kavel inhoud</b>Hier wordt de inhoud van de kavel toegevoegd.
            </p>

            <p>
              <b>Is de kavel gekoppeld aan een handelaar</b>Vul de prijs van de
              kavel in het veld "Kavel prijs" in.
            </p>
          </template>

          <template v-else-if="e13 === 2">
            <p>
              Vul de omschrijving van de kavel in het veld "Omschrijving" in.
            </p>
            <p>
              Voeg afbeeldingen toe door te slepen of te klikken in het
              "Afbeelding toevoegen" gedeelte.
            </p>
          </template>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="showInstructionsDialog = false"
            >Sluiten</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import apiLand from "../api/apiLand";
import apiTrader from "../api/apiTrader";
import apiState from "../api/apiState";
import apiType from "../api/apiEmbCode";
import apiStatus from "../api/apiStatus";
import apiKavel from "../api/apiKavel";
export default {
  data: () => ({
    showInstructionsDialog: false,

    e13: 1,
    landen: [],
    traders: [],
    countryVisibilitys: ["Alleen NL", "Allebei"],
    embcodes: [],
    states: [],
    statussen: [],
    request: ["Ja", "Nee"],
    form: {
      hideOnEnglishSite: false,
      embEN: "",
      kenmerk:"",
      weight: null,
      aantal: 1,
      inhoudEN: "",
      inhoudNL: "",
      countryVisibility: "",
      onRequest: "",
      trader: "",
      purchasePrice: "",
      number: "",
      land: "",
      txtNed: "",
      txtEN: "",
      price: "",
      status: "",
      embNed: "",
    },
  }),
  watch: {
    "form.price"(newPrice) {
      if (newPrice) {
        this.form.purchasePrice = (parseFloat(newPrice) / 1.65).toFixed(2);
      } else {
        this.form.purchasePrice = null;
      }
    },
  },

  mounted() {
    apiLand.getLanden().then((data) => {
      this.landen = data;
      console.log(data);
    });

    apiStatus.getStatus().then((data) => {
      this.statussen = data;
    });
    apiTrader.getTraders().then((data) => {
      this.traders = data;
    });
    apiType.getTypes().then((data) => {
      this.embcodes = data;
    });
    apiState.getStates().then((data) => {
      this.states = data;
    });
    const formData = localStorage.getItem("formData");
    if (formData) {
      this.form = JSON.parse(formData);
    }
  },
  methods: {
    resetFields(){
      this.form = {
      landName: '',
      purchasePrice: '',
      price: '',
      embNed: '',
      statusName: '',
      inhoudNL: '',
      traderName: '',
      onRequest: '',
      weight: '',
      ebay: false,
      ebayId: '',
      txtNed: '',
      txtEN: '',
      kenmerk:'',
    }
  },
    backToKavels() {
      this.$router.push("/Kavelsbeheren");
    },
    nextStep() {
      this.currentStep = "complete";
    },

    async addKavel() {
      this.form.hideOnEnglishSite = this.form.countryVisibility === "Alleen NL";
      try {
        const response = await apiKavel.createKavel(this.form);
        console.log(response);
        const kavelUrl = `/SingleKavel/${response.kavelNumber}`;
        this.$swal.fire({
          title: "Yes!",
          html:
            `Het aanmaken is gelukt! Kavelnummer ${response.kavelNumber} <br><br>` +
            `<a href="${kavelUrl}" target="_blank" class="btn btn-success">Open Kavel</a>`,
          icon: "success",
          confirmButtonText: "Sluiten",
        });
      } catch (error) {
        console.error(error);
        this.$swal.fire(
          "Er is een foutmelding!",
          "Probeer het nogmaals!",
          "error"
        );
      }
    },
  },
};
</script>
