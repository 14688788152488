<template>
  <v-container fluid>
    <v-btn small color="warning" @click="openInstructions"
      >Rapporten uitdraaien instructies openen</v-btn
    >
    <div
      style="margin-bottom: 5px; display: flex; justify-content: flex-end"
    ></div>
    <h5>Kavels rapporten uitdraaien</h5>
    <div class="row">
      <div class="col-12 col-lg-4 mb-4">
        <div>
          <div>
            <div class="link-box">
              <h2>Rapport met Status</h2>
              <p class="link-box-title">Uitdraaien van kavels met een status</p>
              <p class="link-box-subtitle">
                Selecteer een direct download of met datum selectie
              </p>
              <v-chip class="ma-2" color="brown" outlined> Kavels </v-chip>
              <div style="display: flex">
                <StatusReport />
                <StatusDateSelectionReport />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-4 mb-4">
        <div>
          <div class="link-box">
            <h2>Start en Eindkavel selectie rapport</h2>
            <p class="link-box-title">
              Uitdraai van selectie tussen kavelnummers
            </p>
            <p class="link-box-subtitle">
              Start en eindkavelnummer invullen en draai het rapport uit
            </p>
            <v-chip class="ma-2" color="brown" outlined> Kavels </v-chip>
            <div style="display: flex">
              <KavelNumberReport />
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-4 mb-4">
        <div>
          <div class="link-box">
            <h2>Kavels aangemaakt op een bepaalde datum rapport</h2>
            <p class="link-box-title">Uitdraai van kavels aangemaakt</p>
            <p class="link-box-subtitle">
              Selecteer een datum van aanmaak van een kavel.
            </p>
            <v-chip class="ma-2" color="brown" outlined> Kavels </v-chip>
            <div style="display: flex">
              <KavelCreatedReport />
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-4 mb-4">
        <div>
          <div class="link-box">
            <h2>Kavels Emcode rapport maken</h2>
            <p class="link-box-title">Uitdraai van kavels aangemaakt</p>
            <p class="link-box-subtitle">
              Maak een selectie van de mogelijkheden en draai een rapport uit
            </p>
            <v-chip class="ma-2" color="brown" outlined> Kavels </v-chip>
            <div style="display: flex">
              <EmbcodeReport />
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-lg-4 mb-4">
        <div>
          <div class="link-box">
            <h2>Handelaar rapport maken</h2>
            <p class="link-box-title">Uitdraai van handelaars</p>
            <p class="link-box-subtitle">
              Maak een selectie van de mogelijkheden en draai een rapport uit
            </p>
            <v-chip class="ma-2" color="brown" outlined> Kavels </v-chip>
            <div style="display: flex">
              <TraderReport />
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-lg-4 mb-4">
        <div>
          <div class="link-box">
            <h2>Kavelprijs rapport maken</h2>
            <p class="link-box-title">Uitdraai van handelaars</p>
            <p class="link-box-subtitle">
              Maak een selectie van de mogelijkheden en draai een rapport uit
            </p>
            <v-chip class="ma-2" color="brown" outlined> Kavels </v-chip>
            <div style="display: flex">
              <KavelPriceReport />
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-lg-4 mb-4">
        <div>
          <div class="link-box">
            <h2>Meerdere handelaren rapport maken</h2>
            <p class="link-box-title">Uitdraai van handelar(en) en status</p>
            <p class="link-box-subtitle">
              Maak een selectie van de mogelijkheden en draai een rapport uit
            </p>
            <v-chip class="ma-2" color="brown" outlined> Handelaar </v-chip>
            <div style="display: flex">
              <MultipleTradersReport />
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-4 mb-4">
        <div>
          <div>
            <div class="link-box">
              <h2>Webshop rapport</h2>
              <p class="link-box-title">Bestelgeschiednis van een persoon</p>
              <p class="link-box-subtitle">
                In Dit rapport kun je de bestellingen van een persoon uitdraaien
              </p>
              <v-chip class="ma-2" color="success" outlined>
                Bestellingen
              </v-chip>

              <WebshopReport />
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-4 mb-4">
        <div>
          <div class="link-box">
            <h2>Rapport op basis van een bestelstatus uidraaien</h2>
            <p class="link-box-title">Selecteer een status en draai uit</p>
            <p class="link-box-subtitle">
              Dit rapport kan je verschillende bestelstatussen uitdraaien
            </p>
            <v-chip class="ma-2" color="success" outlined>
              Bestellingen
            </v-chip>
            <BookkeeperReport />
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
//OrderStatus
import BookkeeperReport from "../reportOrders/BookkeeperReport.vue";
import WebshopReport from "../reportOrders/WebshopReport.vue";
//Kavel status
import MultipleTradersReport from "../reportKavels/MultipleTradersReport.vue";
import EmbcodeReport from "../reportKavels/EmbcodeReport.vue";
import StatusReport from "../reportKavels/StatusReport.vue";
import StatusDateSelectionReport from "../reportKavels/StatusDateSelectionReport.vue";
import KavelNumberReport from "../reportKavels/KavelNumberReport.vue";
import KavelCreatedReport from "../reportKavels/KavelCreatedReport.vue";
import TraderReport from "../reportKavels/TraderReport.vue";
import KavelPriceReport from "../reportKavels/KavelPriceReport.vue";

export default {
  components: {
    WebshopReport,
    BookkeeperReport,
    KavelCreatedReport,
    TraderReport,
    StatusReport,
    StatusDateSelectionReport,
    KavelNumberReport,
    EmbcodeReport,
    KavelPriceReport,
    MultipleTradersReport,
  },
  data() {
    return {};
  },
  methods: {
    openInstructions() {
      this.$router.push("/instructies");
    },
  },
};
</script>

<style scoped>
.router-link {
  color: black;
  text-decoration: none;
}
.link-box {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1), 0 5px 8px 0 rgba(0, 0, 0, 0.05);
  padding: 20px;
  font-size: 16px;
  text-align: center;
  border-top: 2px solid #90caf9;

  /* min-height:100%; */
}
.link-box p {
  margin: 0px 10px 10px 0px;
}
.link-box img {
  height: 40px;
  margin-bottom: 10px;
}
.link-box-title {
  color: black;
  font-size: 16px;
}
.link-box-subtitle {
  color: rgb(63, 63, 63);
  font-size: 14px;
  margin-bottom: 10px;
}
h2 {
  font-size: 18px;
  font-weight: bold;
}
</style>
