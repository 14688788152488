<template>
  <v-container fluid>
    <form ref="form" @submit.prevent="submitForm">
      <v-stepper v-model="e13" vertical>
        <v-stepper-step step="1">
          Je heb nu Kavel
          <b style="color: orange"> {{ this.form.kavelnr }}</b>
          geopend.informatie (Alleen de Status kan bewerkt worden)

          <v-btn
            rounded
            color="primary"
            @click="copyDataToLocalStorageAndNavigate()"
            >Kopieer Kavel</v-btn
          >
          <v-btn
            v-if="!form.ebay"
            rounded
            style="margin-left: 2px"
            color="warning"
            @click="addToEbay()"
          >
            Toevoegen op Ebay
          </v-btn>

          <v-btn
            v-if="form.ebay"
            rounded
            style="margin-left: 2px"
            color="error"
            @click="updateEbay()"
          >
            kavel updaten
          </v-btn>

          <v-btn
            v-if="form.ebay"
            rounded
            style="margin-left: 2px"
            color="warning"
            @click="deleteEbay()"
          >
            Verwijderen uit Ebay
          </v-btn>
        </v-stepper-step>

        <v-stepper-content step="1">
          <v-card>
            <v-card-title> Kavel </v-card-title>

            <v-row>
              <v-col cols="4">
                <label>Kavel land</label>
                <v-text-field
                  v-if="!editLand"
                  disabled
                  dense
                  outlined
                  v-model="form.landName"
                ></v-text-field>
                <v-autocomplete
                  v-if="editLand"
                  v-model="form.landName"
                  :items="landen.flat()"
                  item-text="Ned"
                  item-value="id"
                  dense
                  outlined
                  :disabled="!editLand"
                  label="Land selecteren"
                ></v-autocomplete>
                <v-btn small color="primary" x-small @click="toggleEditLand">{{
                  editLand ? "Klaar" : "Bewerk Land"
                }}</v-btn>
              </v-col>

              <v-col cols="4">
                <label> Kavel inkoopprijs</label>
                <v-text-field
                  disabled
                  dense
                  outlined
                  prefix="Euro"
                  placeholder="Inkoopprijs"
                  v-model="form.purchasePrice"
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <label> Kavel prijs</label>

                <v-text-field
                  v-if="editPrice"
                  dense
                  prefix="Euro"
                  outlined
                  v-model="form.price"
                >
                </v-text-field>
                <v-text-field
                  v-if="!editPrice"
                  dense
                  disabled
                  prefix="Euro"
                  outlined
                  v-model="form.price"
                >
                </v-text-field>
                <v-btn small color="primary" x-small @click="toggleEditPrice">{{
                  editPrice ? "Klaar" : "Bewerk prijs"
                }}</v-btn>
              </v-col>
              <v-col cols="4">
                <label>Embcode kavel selecteren</label>
                <v-autocomplete
                  dense
                  outlined
                  v-if="editInhoudEmbcode"
                  :items="embcodes.flat()"
                  label="Embcode selecteren"
                  v-model="form.embNed"
                  item-text="nameNL"
                  item-value="nameNL"
                ></v-autocomplete>
                <v-text-field
                  disabled
                  v-if="!editInhoudEmbcode"
                  dense
                  outlined
                  v-model="form.embNed"
                >
                </v-text-field>

                <v-btn
                  small
                  color="primary"
                  x-small
                  @click="toggleEditInhoudEmbcode"
                  >{{ editInhoudEmbcode ? "Klaar" : "Bewerk embcode" }}</v-btn
                >
              </v-col>
              <v-col cols="4">
                <label> Kavel Status </label>
                <v-text-field
                  v-if="!editStatus"
                  disabled
                  dense
                  outlined
                  v-model="form.statusName"
                ></v-text-field>
                <v-autocomplete
                  v-else
                  dense
                  outlined
                  :items="statussen.flat()"
                  label="Status selecteren"
                  v-model="form.statusName"
                  item-text="name"
                  item-value="id"
                ></v-autocomplete>
                <v-btn
                  small
                  color="primary"
                  x-small
                  @click="toggleEditStatus"
                  >{{ editStatus ? "Klaar" : "Bewerk Status" }}</v-btn
                >
              </v-col>
              <v-col cols="4">
                <label> Kavel inhoud </label>
                <v-text-field
                  v-if="!editInhoudEmbcode"
                  dense
                  disabled
                  outlined
                  v-model="form.inhoudNL"
                ></v-text-field>

                <v-autocomplete
                  dense
                  v-if="editInhoudEmbcode"
                  outlined
                  :items="states.flat()"
                  label="Inhoud selecteren"
                  v-model="form.inhoudNL"
                  item-text="nameNL"
                  item-value="name"
                ></v-autocomplete>

                <v-btn
                  small
                  color="primary"
                  x-small
                  @click="toggleEditInhoudEmbcode"
                  >{{ editInhoudEmbcode ? "Klaar" : "Bewerk inhoud" }}</v-btn
                >
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="4">
                <label> Gekoppelde handelaar</label>

                <v-text-field
                  v-if="!editTrader"
                  disabled
                  dense
                  outlined
                  v-model="form.traderName"
                ></v-text-field>

                <v-autocomplete
                  dense
                  outlined
                  v-if="editTrader"
                  :items="traders.flat()"
                  label="Handelaar selecteren"
                  item-text="name"
                  item-value="id"
                  v-model="form.trader"
                ></v-autocomplete>
                <v-btn small color="primary" x-small @click="toggleTrader">{{
                  editTrader ? "Klaar" : "Bewerk Handelaar"
                }}</v-btn>
              </v-col>
              <v-col cols="4">
                <label> Op verzoek veld</label>
                <v-text-field
                  dense
                  disabled
                  outlined
                  v-model="form.onRequest"
                ></v-text-field>
              </v-col>

              <v-col cols="4">
                <label> Kavel gewicht</label>
                <v-text-field
                  dense
                  v-if="!editWeight"
                  type="number"
                  disabled
                  outlined
                  v-model="form.weight"
                ></v-text-field>

                <v-text-field
                  dense
                  v-if="editWeight"
                  type="number"
                  outlined
                  v-model="form.weight"
                ></v-text-field>

                <v-btn
                  small
                  color="primary"
                  x-small
                  @click="toggleEditWeight"
                  >{{ editWeight ? "Klaar" : "Bewerk gewicht" }}</v-btn
                >
              </v-col>

              <v-col cols="4">
                <!-- Kenmerk-->
                <label> Kenmerk</label>
                <v-text-field
                  dense
                  v-if="!editKenmerk"
                  disabled
                  outlined
                  v-model="form.kenmerk"
                ></v-text-field>

                <v-text-field
                  dense
                  v-if="editKenmerk"
                  outlined
                  v-model="form.kenmerk"
                ></v-text-field>

                <v-btn
                  small
                  color="primary"
                  x-small
                  @click="toggleEditKenmerk"
                  >{{ editKenmerk ? "Klaar" : "Bewerk kenmerk" }}</v-btn
                >
              </v-col>
              <v-col cols="4">
                <!-- Kenmerk-->
                <label> Invoerdatum</label>
                <v-text-field
                  dense
                  disabled
                  outlined
                  v-model="formattedDate"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                v-if="
                  form.ebay == true &&
                  form.eBayItems &&
                  form.eBayItems.length > 0
                "
                cols="12"
              >
                <v-card>
                  <v-card-title>eBay Items</v-card-title>
                  <v-card-text>
                    <v-simple-table>
                      <thead>
                        <tr>
                          <th class="text-left">ID</th>
                          <th class="text-left">Item ID</th>
                          <th class="text-left">Site Code</th>
                          <th class="text-left">Site Name</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in form.eBayItems" :key="item.id">
                          <td>{{ item.id }}</td>
                          <td>{{ item.itemId }}</td>
                          <td>{{ item.siteCode }}</td>
                          <td>{{ item.siteName }}</td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <div style="margin-top: 5px">
              <v-btn color="primary" @click="e13 = 2"> Volgende </v-btn>
              <v-btn color="error" @click="annulleer()" text> Annuleer </v-btn>
            </div>
          </v-card>
        </v-stepper-content>

        <v-stepper-step step="2"> Media & Omschrijving </v-stepper-step>

        <v-stepper-content step="2">
          <v-card>
            <v-row>
              <v-col cols="12">
                <div v-if="kavelimages && kavelimages.length > 0">
                  <b>Kavel heeft {{ kavelimages.length }} afbeelding(en)</b>

                  <v-btn
                    @click="goKavelFolder()"
                    elevation="2"
                    small
                    color="primary"
                    dark
                    aria-label="Folder openen"
                  >
                    Folder openen
                    <v-icon small color="warning">folder_open</v-icon>
                  </v-btn>
                </div>

                <br />

                <label class="typo__label"><b>Afbeelding toevoegen</b> </label>

                <v-form ref="form" @submit.prevent="submitForm">
                  <v-file-input
                    v-model="files"
                    label="Selecteer afbeeldingen"
                    multiple
                    :show-size="true"
                    @change="handleFileChange"
                  ></v-file-input>
                </v-form>
                <v-btn small color="primary" @click="uploadFiles()">
                  Afbeeldingen uploaden
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <label> Omschrijving *</label>
                <textarea
                  dense
                  class="form-control"
                  rows="4"
                  placeholder="Omschrijving"
                  data-counter="350"
                  name="description"
                  cols="50"
                  id="description"
                  v-model="form.txtNed"
                ></textarea>
              </v-col>

              <v-col cols="6">
                <label> Omschrijving Engels</label>
                <textarea
                  dense
                  class="form-control"
                  rows="4"
                  placeholder="Omschrijving"
                  data-counter="350"
                  name="description"
                  cols="50"
                  id="description"
                  v-model="form.txtEN"
                ></textarea>
              </v-col>
            </v-row>
            <div style="margin-top: 5px">
              <v-btn small color="primary" @click="updatetext()">
                Omschrijving opslaan</v-btn
              >
              <v-btn color="error" @click="annulleer()" text> Annuleer </v-btn>

              <v-btn color="warning" @click="e13 = 1"> Ga terug </v-btn>
            </div>
          </v-card>
        </v-stepper-content>
      </v-stepper>
    </form>
  </v-container>
</template>

<script>
import apiEbay from "../api/apiEbay";
import axios from "axios";
import apiTrader from "../api/apiTrader";
import apiKavel from "../api/apiKavel";
import apiState from "../api/apiState";
import apiType from "../api/apiEmbCode";
import apiStatus from "../api/apiStatus";
import apiLand from "../api/apiLand";
import apiFolder from "../api/apiKavelFolder";
export default {
  data: () => ({
    files: [],
    editTrader: false,
    editInhoudEmbcode: false,
    editStatus: false,
    editKenmerk: false,
    editPrice: false,
    editWeight: false,
    images: [],
    kavelimages: [],
    e13: 1,
    editLand: false,
    request: ["Ja", "Nee"],
    landen: [],
    traders: [],
    embcodes: [],
    countryVisibilitys: ["NL", "ENG", "Allebei"],
    states: [],
    statussen: [],
    form: {},
  }),

  computed: {
    ebayClass() {
      console.log(
        `Ebay class updated: ${
          this.form.ebay ? "checkbox-green" : "checkbox-red"
        }`
      );
      return this.form.ebay ? "checkbox-green" : "checkbox-red";
    },
    formattedDate() {
      if (!this.form.createDate) return "";

      const date = new Date(this.form.createDate);
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
      const year = date.getFullYear();

      return `${day}/${month}/${year}`;
    },
  },

  mounted() {
    this.fetchKavel();
    this.fetchOptions();
  },
  methods: {
    copyDataToLocalStorageAndNavigate() {
      localStorage.setItem("formData", JSON.stringify(this.form));
      this.$router.push("/KavelToevoegen");
    },
    goKavelFolder() {
      this.$router.push("/KavelsFolder");
    },
    fetchOptions() {
      apiStatus.getStatus().then((data) => {
        this.statussen = data;
      });
      apiTrader.getTraders().then((data) => {
        this.traders = data;
      });
      apiType.getTypes().then((data) => {
        this.embcodes = data;
      });
      apiState.getStates().then((data) => {
        this.states = data;
      });
      apiLand.getLanden().then((data) => {
        this.landen = data;
      });
    },
    async fetchKavel() {
      return apiKavel.getSingleKavel(this.$route.params.id).then((data) => {
        this.form = data;
        this.fetchImagesForKavel(this.form.kavelnr);
      });
    },

    async updatetext() {
      const id = this.$route.params.id;
      try {
        const text = {
          txtNed: this.form.txtNed,
          txtEN: this.form.txtEN,
        };
        await apiKavel.updateText(id, text);
        await this.fetchKavel();
        this.$swal.fire("Omschrijving succesvol bijgewerkt", "", "success");
      } catch (err) {
        console.log(err);
        this.$swal.fire(
          "Er is een fout opgetreden bij het bijwerken van het Omschrijving",
          "",
          "error"
        );
      }
    },

    async updateEbayStatusFalse() {
      const id = this.$route.params.id;
      try {
        await apiKavel.updateAddToIb(id, false);

        this.$swal.fire("Omschrijving succesvol bijgewerkt", "", "success");
      } catch (err) {
        console.log(err);
        this.$swal.fire(
          "Er is een fout opgetreden bij het bijwerken van het Omschrijving",
          "",
          "error"
        );
      }
    },

    async updateEbayStatus() {
      const id = this.$route.params.id;
      try {
        await apiKavel.updateAddToIb(id, true);

        this.$swal.fire("Omschrijving succesvol bijgewerkt", "", "success");
      } catch (err) {
        console.log(err);
        this.$swal.fire(
          "Er is een fout opgetreden bij het bijwerken van het Omschrijving",
          "",
          "error"
        );
      }
    },

    async addToEbay() {
      try {
        await apiEbay.postToEbay(this.form);
        await this.updateEbayStatus();
        await this.fetchKavel();
        this.$swal.fire(
          "De kavel succesvol verstuurd naar Ebay",
          "",
          "success"
        );
      } catch (err) {
        console.log(err);
        this.$swal.fire(
          "Er is een fout opgetreden bij het versturen van de kavel",
          "",
          "error"
        );
      }
    },

    async deleteEbay() {
      try {
        const id = this.$route.params.id;
        await apiEbay.deleteEbay(id);
        await this.updateEbayStatusFalse();
        await this.fetchKavel();
        this.$swal.fire("De kavel is uit Ebay verwijderd", "", "warning");
      } catch (err) {
        console.log(err);
        this.$swal.fire(
          "Er is een fout opgetreden bij het versturen van de kavel",
          "",
          "error"
        );
      }
    },

    async updateEbay() {
      try {
        await this.deleteEbay();
        await this.addToEbay;
      } catch (error) {
        console.log("Error updating Ebay");
      }
    },

    toggleEditLand() {
      if (this.editLand) {
        this.updateKavelLand();
      }
      this.editLand = !this.editLand;
    },

    toggleEditWeight() {
      if (this.editWeight) {
        this.updateKavelWeight();
      }
      this.editWeight = !this.editWeight;
    },

    toggleEditKenmerk() {
      if (this.editKenmerk) {
        this.updateKavelKenmerk();
      }
      this.editKenmerk = !this.editKenmerk;
    },
    //
    toggleTrader() {
      if (this.editTrader) {
        this.updateTrader();
      }
      this.editTrader = !this.editTrader;
    },
    async updateTrader() {
      const id = this.$route.params.id;
      try {
        const updateTrader = {
          traderId: this.form.trader,
        };
        await apiKavel.updateTrader(id, updateTrader);
        await this.fetchKavel();
        this.$swal.fire("Handelaar succesvol bijgewerkt", "", "success");
      } catch (err) {
        console.log(err);
        this.$swal.fire(
          "Er is een fout opgetreden bij het bijwerken van de prijs",
          "",
          "error"
        );
      }
      this.editTrader = false;
    },

    //

    toggleEditInhoudEmbcode() {
      if (this.editInhoudEmbcode) {
        this.updateInhoudEmbcode();
      }
      this.editInhoudEmbcode = !this.editInhoudEmbcode;
    },

    async updateInhoudEmbcode() {
      const id = this.$route.params.id;
      try {
        const updateInformation = {
          inhoudNL: this.form.inhoudNL,
          embNed: this.form.embNed,
        };
        await apiKavel.updatePrice(id, updateInformation);
        await this.fetchKavel();
        this.$swal.fire("Prijs succesvol bijgewerkt", "", "success");
      } catch (err) {
        console.log(err);
        this.$swal.fire(
          "Er is een fout opgetreden bij het bijwerken van de prijs",
          "",
          "error"
        );
      }
      this.editPrice = false;
    },

    async updateKavelLand() {
      const id = this.$route.params.id;
      try {
        await apiKavel.updateLand(id, { id: this.form.landName });
        await this.fetchKavel();
        this.$swal.fire("Land succesvol bijgewerkt", "", "success");
      } catch (err) {
        console.log(err);
        this.$swal.fire(
          "Er is een fout opgetreden bij het bijwerken van het land",
          "",
          "error"
        );
      }
    },

    toggleEditPrice() {
      if (this.editPrice) {
        this.updatePrice();
      }
      this.editPrice = !this.editPrice;
    },

    async updatePrice() {
      const id = this.$route.params.id;
      try {
        const updatedPriceData = {
          price: this.form.price,
          purchasePrice: this.form.purchasePrice,
        };
        await apiKavel.updatePrice(id, updatedPriceData);
        await this.fetchKavel();
        this.$swal.fire("Prijs succesvol bijgewerkt", "", "success");
      } catch (err) {
        console.log(err);
        this.$swal.fire(
          "Er is een fout opgetreden bij het bijwerken van de prijs",
          "",
          "error"
        );
      }
      this.editPrice = false;
    },

    toggleEditStatus() {
      if (this.editStatus) {
        this.updateKavelStatus();
      }
      this.editStatus = !this.editStatus;
    },

    fetchImagesForKavel(kavelnr) {
      const folderName = `kavels/${kavelnr}`;
      apiFolder.fetchImages(folderName).then((data) => {
        this.kavelimages = data;
      });
    },
    imageSrc(loc) {
      return `https://server.postzegels.com/${loc}`;
    },
    annulleer() {
      this.$router.push("../../Kavelsbeheren");
    },

    async updateKavelStatus() {
      const id = this.$route.params.id;
      try {
        await apiKavel.updateStatus(id, { id: this.form.statusName });
        await this.fetchKavel();
        this.$swal.fire("Status succesvol bijgewerkt", "", "success");
      } catch (err) {
        console.log(err);
        this.$swal.fire(
          "Er is een fout opgetreden bij het bijwerken van de status",
          "",
          "error"
        );
      }
    },
    async updateKavelWeight() {
      const id = this.$route.params.id;
      try {
        await apiKavel.updateWeight(id, this.form.weight);
        await this.fetchKavel();
        this.$swal.fire("Gewicht succesvol bijgewerkt", "", "success");
      } catch (err) {
        console.log(err);
        this.$swal.fire(
          "Er is een fout opgetreden bij het bijwerken van de gewicht",
          "",
          "error"
        );
      }
    },

    async updateKavelKenmerk() {
      const id = this.$route.params.id;
      try {
        await apiKavel.updateKenmerk(id, this.form.kenmerk);
        await this.fetchKavel();
        this.$swal.fire("kenmerk succesvol bijgewerkt", "", "success");
      } catch (err) {
        console.log(err);
        this.$swal.fire(
          "Er is een fout opgetreden bij het bijwerken van de kenmerk",
          "",
          "error"
        );
      }
    },

    //Upload
    handleFileChange(files) {
      this.files = files;
    },

    async uploadFiles() {
      const id = this.$route.params.id;
      if (this.files.length > 0) {
        for (let i = 0; i < this.files.length; i++) {
          const formData = new FormData();
          formData.append("file", this.files[i]);

          try {
            const response = await axios.post(
              `https://server.postzegels.com/api/rest/v2/kavelfolder/processing/upload/${id}`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );
            console.log("Bestand succesvol geüpload", response);
          } catch (error) {
            console.error("Er is een fout opgetreden bij het uploaden", error);
            this.$swal.fire("Upload fout", "", "error");
            return;
          }
        }
        this.files = [];
        this.$swal.fire(
          "Alle bestanden zijn succesvol geüpload",
          "",
          "success"
        );
      }
    },
  },
};
</script>
<style scoped>
.checkbox-green .v-label {
  color: green !important;
}

.checkbox-red .v-label {
  color: red !important;
}
</style>
